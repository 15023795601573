<template>
    <div>
        <el-card>
            <div class="update_content">
                <div class="update_head">系统更新</div>
                <div class="update_nav">
                    <div class="update_nav_dangqian">
                        <img src="~@/assets/images/system_update/current.png" alt="当前版本" class="update_nav_dangqian_img">
                        <div class="update_nav_dangqian_content">
                            <p>当前版本</p>
                            <p>{{ updatesData.host_version }}</p>
                        </div>
                    </div>
                    <div class="update_nav_dangqian">
                        <img src="~@/assets/images/system_update/estimate.png" alt="预计下一次更新"
                            class="update_nav_dangqian_img">
                        <div class="update_nav_dangqian_content">
                            <p>预计下一次更新</p>
                            <p>{{ updatesData.next_day }}<span>天</span></p>
                        </div>
                    </div>
                    <div class="update_nav_dangqian">
                        <img src="~@/assets/images/system_update/iteration.png" alt="总迭代更新" class="update_nav_dangqian_img">
                        <div class="update_nav_dangqian_content">
                            <p>总迭代更新</p>
                            <p>{{ updatesData.iteration_num }}<span>次</span></p>
                        </div>
                    </div>
                    <div class="update_nav_dangqian">
                        <img src="~@/assets/images/system_update/security.png" alt="程序安全运行" class="update_nav_dangqian_img">
                        <div class="update_nav_dangqian_content">
                            <p>程序安全运行</p>
                            <p>{{ updatesData.safe_run }}<span>天</span></p>
                        </div>
                    </div>
                </div>
                <template v-if="updatesData.next_version">
                    <div class="update_title"></div>
                    <div class="update_record">
                        <div class="update_record_head">
                            <div class="update_record_head_version">版本号: {{ updatesData.next_version.version }}</div>
                            <div class="update_record_head_time">发布时间：{{ updatesData.next_version.add_time }}</div>
                        </div>
                        <div class="update_record_nav">
                            <div class="update_record_nav_describe" v-if="updatesData.next_version.content">
                                <div class="update_record_nav_describe_title">版本描述</div>
                                <!-- <div class="update_record_nav_describe_content"
                                    v-html="format_html(updatesData.next_version.content)"></div> -->
                            </div>
                            <div class="update_record_nav_describe" v-if="updatesData.next_version.added_desc">
                                <div class="update_record_nav_describe_title">新增</div>
                                <div class="update_record_nav_describe_content"
                                    v-html="format_html(updatesData.next_version.added_desc)"></div>
                            </div>
                            <!-- <div class="update_record_nav_describe" v-if="updatesData.next_version.repair_desc">
                                <div class="update_record_nav_describe_title">修复</div>
                                <div class="update_record_nav_describe_content"
                                    v-html="format_html(updatesData.next_version.repair_desc)"></div>
                            </div> -->
                            <div class="update_record_nav_describe" v-if="updatesData.next_version.optimize_desc">
                                <div class="update_record_nav_describe_title">优化</div>
                                <div class="update_record_nav_describe_content"
                                    v-html="format_html(updatesData.next_version.optimize_desc)"></div>
                            </div>
                            <div class="update_record_nav_promptly" style="margin-bottom: 5px" size="small" plain
                                type="primary" :loading="updating" @click="updateConfirmDialogVisible = true" v-if="hasPerm(['system.version.renew'])">
                                {{ updating ? '更新中' : '立即更新' }}
                            </div>
                        </div>
                    </div>
                </template>
                <div class="update_title">更新记录</div>
                <template v-if="updatesData.list && updatesData.list.length">
                    <template v-for="(item, index) in updatesData.list">
                        <div class="update_record">
                            <div class="update_record_head">
                                <div class="update_record_head_version">版本号: {{ item.version }}</div>
                                <div class="update_record_head_time">发布时间：{{ item.create_time }}</div>
                            </div>
                            <div class="update_record_nav">
                                <div class="update_record_nav_describe" v-if="item.content">
                                    <div class="update_record_nav_describe_title">版本描述</div>
                                    <!-- <div class="update_record_nav_describe_content" v-html="format_html(item.content)">
                                    </div> -->
                                </div>
                                <div class="update_record_nav_describe" v-if="item.added_desc">
                                    <div class="update_record_nav_describe_title">新增</div>
                                    <div class="update_record_nav_describe_content" v-html="format_html(item.added_desc)">
                                    </div>
                                </div>
                                <!-- <div class="update_record_nav_describe" v-if="item.repair_desc">
                                    <div class="update_record_nav_describe_title">修复</div>
                                    <div class="update_record_nav_describe_content" v-html="format_html(item.repair_desc)">
                                    </div>
                                </div> -->
                                <div class="update_record_nav_describe" v-if="item.optimize_desc">
                                    <div class="update_record_nav_describe_title">优化</div>
                                    <div class="update_record_nav_describe_content"
                                        v-html="format_html(item.optimize_desc)"></div>
                                </div>
                                <div class="update_record_nav_time">
                                    <p>更新时间：<span>{{ item.update_time }}</span></p>
                                    <p></p>
                                    <p>更新人：<span>{{ item.update_user_name }}</span></p>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <div v-else style="color: #909399;margin-top: 10px;">暂无更新记录</div>
            </div>
        </el-card>
        <el-dialog v-if="updatesData.next_version" title="提示" :visible.sync="updateConfirmDialogVisible" width="600px">
            <div style="margin-bottom: 15px">确认升级到版本: {{ updatesData.next_version.version }}</div>
            <div>
                <div style="margin-bottom: 15px">更新协议:</div>
                <el-checkbox v-model="updateAgreement.backup">已经做好了相关文件和数据库的备份工作，并自愿承担更新所存在的风险</el-checkbox>
            </div>
            <div slot="footer">
                <el-button @click="updateConfirmDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateNow">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            updatesData: {},
            updating: false,
            updateConfirmDialogVisible: false,
            updateAgreement: {
                backup: false,
            },
        }
    },
    created() {
        // 获取历史版本信息
        this.getUpdateData()
    },
    methods: {
        getUpdateData() {
            this.$get(this.$apis.sysUpdateInfo).then(res => {
                if (res.code == 200) {
                    this.updatesData = res.data
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 格式化 html
        format_html(html) {
            var reg = /<p>(<br>|&nbsp;|\\s)*<\/p>/g;
            html = html.replace(reg, '');// 去除由富文本编辑生成的空行
            return html;
        },
        updateNow() {
            if (!this.updateAgreement.backup) {
                this.$message.error('请先确认更新协议');
                return;
            }
            this.updateConfirmDialogVisible = false;
            this.update();
        },
        update() {
            this.updating = true;
            let stopContent = '系统更新中，请勿关闭或刷新页面！';
            window.onbeforeunload = function (event) {
                event = event || window.event;
                if (event) {
                    event.returnValue = stopContent;
                }
                return stopContent
            };
            if (parent && parent.stopPageClose) {
                parent.stopPageClose(true, stopContent);
            }
            let that = this;
            this.$post(this.$apis.sysUpdateRenew).then(res => {
                window.onbeforeunload = null;
                if (parent && parent.stopPageClose) {
                    parent.stopPageClose(false);
                }
                if (res.code === 200) {
                    this.$message.success('更新成功');
                    that.getUpdateData()
                } else {
                    this.$message.error(res.message);
                    this.updating = false;
                }
            }).catch(error => {
                //console.log(error, 'error');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.update_content {
    .update_head {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-bottom: 10px;
        border-bottom: solid 1px #ECEEEF;
    }

    .update_nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .update_nav_dangqian {
            width: 23%;
            height: 120px;
            background: #FFEFEF;
            border-radius: 0px 26px 26px 26px;
            display: flex;
            align-items: center;
            // margin-right: 2.6%;
            margin-top: 20px;

            &:nth-child(2) {
                background: #F1F5FE;
            }

            &:nth-child(3) {
                background: #EDF9FF;
            }

            &:nth-child(4) {
                background: #FFF7ED;
                margin-right: 0;
            }
        }

        .update_nav_dangqian_img {
            width: 64px;
            height: 64px;
            margin-left: 24px;
        }

        .update_nav_dangqian_content {
            margin-left: 16px;

            p {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;

                &:nth-child(2) {
                    font-size: 28px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    line-height: 40px;
                }

                &:nth-child(2) span {
                    margin-top: 14px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
            }
        }
    }

    .update_record {
        width: 100%;
        background-color: #F7F8FA;
        border-radius: 4px;
        margin-top: 20px;
        padding-bottom: 24px;

        .update_record_head {
            display: flex;
            align-items: center;
        }

        .update_record_head_version {
            width: 149px;
            height: 36px;
            background: var(--fontColor);
            border-radius: 4px 0px 16px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 16px;
        }

        .update_record_head_time {
            margin-left: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
        }

        .update_record_nav {
            margin-top: 8px;
            margin-left: 24px;
        }

        .update_record_nav_describe {
            display: flex;
            align-items: flex-start;
            padding-top: 24px;
        }

        .update_record_nav_describe_title {
            width: 80px;
            height: 24px;
            background: #EDF4FF;
            border-radius: 2px;
            border: 1px solid #5487DF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5487DF;
            line-height: 20px;
        }

        .update_record_nav_describe_content {
            margin-left: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
        }

        .update_record_nav_describe_content p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
        }

        .update_record_nav_describe:nth-child(2) .update_record_nav_describe_title {
            background: #F0FAF2;
            color: #66BE74;
            border: 1px solid #66BE74;
        }

        .update_record_nav_describe:nth-child(3) .update_record_nav_describe_title {
            background: #FFF5ED;
            color: #F1924E;
            border: 1px solid #F1924E;
        }

        .update_record_nav_time {
            margin-top: 24px;
            margin-left: 100px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            display: flex;
            align-items: center;
        }

        .update_record_nav_time p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
            display: flex;
            align-items: center;
        }

        .update_record_nav_time p span {
            color: #666666;
            font-size: 14px;
        }

        .update_record_nav_time p:nth-child(2) {
            margin-left: 10px;
            margin-right: 10px;
            width: 2px;
            height: 14px;
            background-color: #999999;
        }

        .update_record_nav_promptly {
            width: 104px;
            height: 32px;
            background: var(--fontColor);
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            margin-top: 24px;
            margin-left: 100px;
            cursor: pointer;
        }
    }

    .update_title {
        margin-top: 30px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
}

/deep/.el-dialog .el-dialog__body {
    padding: 20px 50px 20px 20px !important;
    box-sizing: border-box;
}

/deep/ .el-button.el-button--primary {
    color: #fff !important;
}

/deep/ .el-button--primary {
    background-color: var(--fontColor);
    border-color: var(--fontColor);
}
</style>